import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// css
import "./App.css";
import "./css/navbar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// components
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home.js";
import Projects from "./pages/Projects";
import Partners from "./pages/Partners";
import ProjectDetails from "./pages/ProjectDetails";
import People from "./pages/People";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/profile/people" component={People} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/profile/partners" component={Partners} />
        <Route exact path="/projects/:p_name" component={ProjectDetails} />
        <Route exact path="/projects/" component={Projects} />
        <Route exact path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
