import React, { Component } from "react";

// components
import NavBar from "../components/NavBar";

// css
import "../css/partners.css";

// data
import { all_partners } from "../data/partners";

export class Partners extends Component {
  render() {
    return (
      <div className="page">
        <NavBar />
        <div className="left partners">
          <div className="section">
            <h3 className="section-heading">Partners</h3>
          </div>
        </div>
        <div className="right">
          <div className="partners-img-container">
            {all_partners.map((partner) => (
              <img
                src={partner}
                alt={partner}
                key={partner}
                className="partner-img"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
