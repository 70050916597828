import React, { Component } from "react";

// data
import { nav_projects, nav_profile, contact, nav_people } from "../data/nav";
import { projects } from "../data/projects";
import logo from "../Images/logo.png";

// import {Link} from 'react-router-dom'

class NavBar extends Component {
  state = {
    open: false,
  };

  toggleNav = () => {
    this.setState({ open: !this.state.open }, () => {
      var root = document.getElementById("root");
      if (root.offsetWidth < 768) {
        if (this.state.open) {
          root.style.height = "100vh";
          root.style.overflow = "hidden";
        } else {
          root.style.overflowY = "scroll";
        }
      }
    });
  };
  render() {
    const { open } = this.state;
    const { active } = this.props;
    // console.log(projects);

    const content = (
      <>
        <div className="top-bar">
          <a className="nav-link" href="/">
            <img src={logo} className="nav-logo" alt={logo} />
          </a>
          <div
            className={open ? "nav-btn open" : "nav-btn"}
            onClick={this.toggleNav}
          ></div>
        </div>
        <div className={!open ? "nav-menu open" : "nav-menu "}>
          <div className="nav-content">
            <div className="section">
              <h3 className="section-heading">
                <a href="/projects">{nav_projects.section_name}</a>
              </h3>
              {projects.map((l) => (
                <a
                  className={active && active === l.name ? "active" : ""}
                  href={`/projects/${l.name}`}
                  key={l.name}
                >
                  {l.name}
                </a>
              ))}
            </div>

            {/* <div className="section">
              <h3 className="section-heading">
                <a href="/profile">{nav_profile.section_name}</a>
              </h3>
              {nav_profile.sub_links.map((l) => (
                <a
                  className={active && active === l.text ? "active" : ""}
                  href={`/profile/${l.text}`}
                  key={l.text}
                >
                  {l.text}
                </a>
              ))}
            </div> */}

            <div className="section">
              <h3 className="section-heading">
                <a href="/profile/People">People</a>
              </h3>
            </div>

            {/* <div className="section">
              <h3 className="section-heading">
                <a href="/profile/Process">Process</a>
              </h3>
            </div> */}

            <div className="section">
              <h3 className="section-heading">
                <a href="/profile/Partners">Partners</a>
              </h3>
            </div>

            {/* <div className="section">
              <h3 className="section-heading">{nav_people.section_name}</h3>
              {nav_people.sub_links.map((l) => (
                <a
                  className={active && active === l.text ? "active" : ""}
                  href={`/profile/people?section=${l.text}`}
                  key={l.text}
                >
                  {l.text}
                </a>
              ))}
            </div> */}

            <div className="section">
              <h3 className="section-heading">
                <a href="/contact-us">Contact Us</a>
              </h3>
              {/* {contact.map((l) => (
                <p className="contact-line">{l}</p>
              ))} */}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <>
        {document.getElementById("root").offsetWidth > 768 ? (
          <div>{content}</div>
        ) : (
          content
        )}
      </>
    );
  }
}

export default NavBar;
