import img from "../Images/Home/img.jpg";
import img1 from "../Images/Home/img1.jpg";
import img2 from "../Images/Home/img2.jpg";
import img3 from "../Images/Home/img3.jpg";
import img4 from "../Images/Home/img4.jpg";
import img5 from "../Images/Home/img5.jpg";
import img6 from "../Images/Home/img6.jpg";

export const homeImages = [
  {
    original: img,
    thumbnail: img,
    // description: {
    //   text: "Render custom slides within the gallery",
    //   href:"https://www.google.com"
    // },
    description: "text1 - text1",
    href: "https://www.google.com",
  },
  {
    original: img1,
    thumbnail: img1,
    description: "text2 - text2",
    href: "https://www.google.com",
  },
  {
    original: img2,
    thumbnail: img2,
    description: "Render custom slides within the gallery",
    href: "https://www.google.com",
  },
  {
    original: img3,
    thumbnail: img3,
    description: "Render custom slides within the gallery",
    href: "https://www.google.com",
  },
  {
    original: img4,
    thumbnail: img4,
    description: "Render custom slides within the gallery",
    href: "https://www.google.com",
  },
  {
    original: img5,
    thumbnail: img5,
    description: "Render custom slides within the gallery",
    href: "https://www.google.com",
  },
  {
    original: img6,
    thumbnail: img6,
    description: "Render custom slides within the gallery",
    href: "https://www.google.com",
  },
];

export const bottomText =
  " We are an experienced contracting firm established in 1973 and specializing in precision construction and high-end governmental and private projects.";

export const onlyImages = [img, img1, img2, img3, img4, img5, img6];
