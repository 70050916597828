import React, { Component } from "react";

// components
import NavBar from "../components/NavBar";

// css
import "../css/contact-us.css";

// data
import { contact } from "../data/nav";

export class ContactUs extends Component {
  state = {
    name: "",
    phone: "",
    subject: "",
    description: "",
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { name, phone, subject, description } = this.state;
    return (
      <div className="page">
        <NavBar />
        <div className="left">
          <div className="projects-nav contact">
            <div className="section">
              <h3 className="section-heading">Address</h3>
              {contact.map((line) => (
                <p className="contact-line" key={line}>{line}</p>
              ))}
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-content contact">
            <h1 className="contact-heading">Contact Us</h1>
            <form className="contact-form">
              <div className="field">
                <p>Name</p>
                <input
                  className="form-input"
                  name="name"
                  onChange={this.onChange}
                  value={name || ""}
                />
              </div>
              <div className="field">
                <p>Phone Number</p>
                <input
                  className="form-input"
                  name="phone"
                  onChange={this.onChange}
                  value={phone || ""}
                />
              </div>
              <div className="field">
                <p>Subject</p>
                <input
                  className="form-input"
                  name="subject"
                  onChange={this.onChange}
                  value={subject || ""}
                />
              </div>
              <div className="field">
                <p>Description</p>
                <textarea
                  className="form-input"
                  name="description"
                  onChange={this.onChange}
                  value={description || ""}
                  rows={6}
                />
              </div>
              <div className="field">
                <button type="submit" className='submit-btn'>SUBMIT</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
