import React, { Component } from "react";

// data
import { nav_projects } from "../data/nav";
import { projects } from "../data/projects";
import { bottomText } from "../data/home";

// components
import NavBar from "../components/NavBar";

// css
import "../css/projects.css";

// import {Link} from 'react-router-dom'

class Projects extends Component {
  render() {
    return (
      <div className="page projects">
        <div className="left">
          <NavBar />
          <div className="projects-nav pro-left">
            <div className="section">
              <h3 className="section-heading">
                <a href="/projects">{nav_projects.section_name}</a>
              </h3>
              {nav_projects.sub_links.map((l) => (
                <a
                  // className={active && active === l.text ? "active" : ""}
                  href={`/projects/${l.name}`}
                  key={l.name}
                >
                  {l.name}
                </a>
              ))}
            </div>
          </div>
          <div className="home-bottom">
            <p className="home-bottom-text">{bottomText}</p>
            <h4 className="home-bottom-sub-text">
              Custom Design + Precision Built.
            </h4>
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            {projects.map((p) => (
              <a
                key={p.name}
                className="prj-container"
                href={`/projects/${p.name}`}
                style={{
                  backgroundImage: "url(" + p.image + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <img src={p.image} alt={p.name} /> */}
                <div className="filter-div"></div>
                <div className="proj-meta">
                  <h3>{p.name}</h3>
                  <p>{p.year}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
