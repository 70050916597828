import React, { Component } from "react";
import Slider from "react-slick";
import qs from "query-string";

// components
import NavBar from "../components/NavBar";

// data
import { nav_people } from "../data/nav";
import {
  all_exe_images,
  all_team_images,
  team_data,
  exe_data,
  all_people_images,
  ordered_data
} from "../data/people";

// css
import "../css/people.css";
import "react-slick/";

// icons
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export class People extends Component {
  state = {
    userData: exe_data,
    images: [],
    slideIndex: 0,
    updateCount: 0,
  };
  componentDidMount() {
    // console.log(all_exe_images);
    // const { section } = qs.parse(window.location.search);
    // let images;
    // let data;
    // if (section) {
    //   switch (section) {
    //     case "Executive Management":
    //       images = all_exe_images;
    //       data = exe_data;
    //       break;

    //     case "Team KIC":
    //       images = all_team_images;
    //       data = team_data;
    //       break;

    //     default:
    //       images = all_exe_images;
    //       break;
    //   }

    // }
    this.setState({
      // images: [...all_exe_images, ...all_team_images],
      images: all_people_images,
      userData: ordered_data,
      // userData: [...exe_data, ...team_data],
    });
    // console.log(all_people_images);
  }

  // increase = () => {
  //   console.log(this.state.slideIndex);
  //   switch (this.state.slideIndex) {
  //     case this.state.userData.length - 1:
  //       this.setState({ slideIndex: 0 });
  //       break;
  //     default:
  //       this.setState({ slideIndex: this.state.slideIndex + 1 });
  //       break;
  //   }
  // };

  render() {
    const { images, slideIndex, userData } = this.state;
    // console.log(slideIndex);

    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () => {
        // console.log("after change");
        this.setState((state) => ({ updateCount: state.updateCount + 1 }));
      },
      beforeChange: (current, next) => {
        // console.log("next", next, current);
        this.setState({ slideIndex: next });
      },
    };
    return (
      <div className="page">
        <NavBar active="People" />
        <div className="left">
          <div className="projects-nav">
            {/* <div className="section">
              <h3 className="section-heading">
                <a href="/projects">{nav_people.section_name}</a>
              </h3>
              {nav_people.sub_links.map((l) => (
                <a
                  className={active && active === l.text ? "active" : ""}
                  href={`/profile/people?section=${l.text}`}
                  key={l.text}
                >
                  {l.text}
                </a>
              ))}
            </div> */}
            <div className="section people">
              <div
                className="left-arrow"
                onClick={() => {
                  var next;
                  if (slideIndex === 0) {
                    next = userData.length - 1;
                  } else {
                    next = slideIndex - 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              >
                <MdKeyboardArrowLeft />
              </div>
              <div
                className="right-arrow"
                onClick={() => {
                  var next;
                  if (slideIndex === userData.length - 1) {
                    next = 0;
                  } else {
                    next = slideIndex + 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              >
                <MdKeyboardArrowRight />
              </div>
              <h3 className="people-heading">
                {/* {images[slideIndex] &&
                  images[slideIndex]
                    .split("/")[3]
                    .slice(0, images[slideIndex].split("/")[3].length - 13)}

                {images.map((item, i) =>
                  console.log(
                    "name:",
                    images[i]
                      .split("/")[3]
                      .slice(0, images[i].split("/")[3].length - 13)
                  )
                )} */}
                {userData[slideIndex] && userData[slideIndex].name}
              </h3>
              <h3 className="people-position">
                {userData[slideIndex] && userData[slideIndex].position}
              </h3>
            </div>
          </div>
          {/* <div className="home-bottom">
            <p className="home-bottom-text">{bottomText}</p>
            <h4 className="home-bottom-sub-text">
              Custom Design + Precision Built.
            </h4>
          </div> */}
        </div>
        <div className="right">
          <div className="right-content">
            <div className="carousel-btns">
              <div
                className="c-prev"
                onClick={() => {
                  var next;
                  if (slideIndex === 0) {
                    next = userData.length - 1;
                  } else {
                    next = slideIndex - 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              ></div>
              <div
                className="c-next"
                onClick={() => {
                  var next;
                  if (slideIndex === userData.length - 1) {
                    next = 0;
                  } else {
                    next = slideIndex + 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              ></div>
            </div>
            <Slider {...settings} ref={(slider) => (this.slider = slider)}>
              {images &&
                images.map((i, key) => (
                  <div key={key} className="slider-img-container people">
                    <img src={i} alt={i} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default People;
