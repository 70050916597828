import React, { Component } from "react";
// import ImageGallery from "react-image-gallery";
import Slider from "react-slick";

// css
import "../css/project-details.css";

// components
import NavBar from "../components/NavBar";

//images
import {
  abu,
  ahmadi,
  al_arbi,
  chips360,
  ishbiliya,
  kuwait_club,
  obada,
  private_villa,
  salmiya,
  sindbad,
  slaughter,
  toby,
  tribe,
} from "../data/project-details";

// icons
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

class ProjectDetails extends Component {
  state = {
    show_details: true,
    img: [],
    description: "",
    slideIndex: 0,
    updateCount: 0,
  };

  componentDidMount() {
    const p_name = this.props.match.params.p_name;
    let data;
    // console.log(p_name);
    switch (p_name) {
      case "Abu Fatira Villa":
        data = abu;
        break;

      case "Ahmadi Club":
        data = ahmadi;
        break;

      case "Al Arabi Club":
        data = al_arbi;
        break;

      case "Chips 360":
        data = chips360;
        break;

      case "Ishbiliya School":
        data = ishbiliya;
        break;

      case "Kuwait Club":
        data = kuwait_club;
        break;

      case "Obada Bin Samed School":
        data = obada;
        break;

      case "Private Villa":
        data = private_villa;
        break;

      case "Salmiya Theater":
        data = salmiya;
        break;

      case "Sindbad":
        data = sindbad;
        break;

      case "Slaughter House":
        data = slaughter;
        break;

      case "Toby's Real Estate":
        data = toby;
        break;

      case "Tribe":
        data = tribe;
        break;

      default:
        // console.log("default");
        break;
    }
    this.setState({
      active: p_name,
      img: data.img,
      description: data.description,
    });
  }

  toggleDetails = () => {
    this.setState({
      show_details: !this.state.show_details,
    });
  };
  render() {
    const { active, show_details, description, img, slideIndex } = this.state;
    // console.log(Object.values(abu_all) ,abu_all)
    // console.log(this.state);

    var settings = {
      dots: true,
      focusOnSelect: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      afterChange: () => {
        // console.log("after change");
        this.setState((state) => ({ updateCount: state.updateCount + 1 }));
      },
      beforeChange: (current, next) => {
        // console.log("next", next, current);
        this.setState({ slideIndex: next });
      },
    };
    return (
      <div className="page">
        <NavBar active={active} />
        <div className="left projects">
          <div className="project-content">
            <div
              className="left-arrow"
              onClick={() => {
                var next;
                if (slideIndex === 0) {
                  next = img.length - 1;
                } else {
                  next = slideIndex - 1;
                }
                this.slider.slickGoTo(next);
              }}
            >
              <MdKeyboardArrowLeft />
            </div>
            <div
              className="right-arrow"
              onClick={() => {
                var next;
                if (slideIndex === img.length - 1) {
                  next = 0;
                } else {
                  next = slideIndex + 1;
                }
                this.slider.slickGoTo(next);
              }}
            >
              <MdKeyboardArrowRight />
            </div>
            <h1 className="project-title">{active}</h1>
            {/* <div
              className={show_details ? "details" : "details hide"}
              onClick={this.toggleDetails}
            >
              Details
            </div>
            <div className={show_details ? "desc" : "desc hide"}>
              <p>{description}</p>
            </div> */}
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            <div className="carousel-btns">
              <div
                className="c-prev"
                onClick={() => {
                  var next;
                  if (slideIndex === 0) {
                    next = img.length - 1;
                  } else {
                    next = slideIndex - 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              ></div>
              <div
                className="c-next"
                onClick={() => {
                  var next;
                  if (slideIndex === img.length - 1) {
                    next = 0;
                  } else {
                    next = slideIndex + 1;
                  }
                  this.slider.slickGoTo(next);
                }}
              ></div>
            </div>
            {/* <ImageGallery
              items={img}
              showNav={true}
              showFullscreenButton={true}
              showPlayButton={false}
            /> */}

            <Slider {...settings} ref={(slider) => (this.slider = slider)}>
              {img.map(({ original }) => (
                <div key={original} className="pro-details-img-div">
                  <img src={original} alt={original} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectDetails;
