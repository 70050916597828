const importAll = (r) => {
  let images = {};
  let img_arr = [];
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  Object.values(images).map((i) => {
    img_arr.push({ original: i.default });
  });
  return img_arr;
};

export const abu_img = importAll(
  require.context("../Images/Projects/Abu Fatira Villa", false, /\.(png|jpe?g|svg)$/)
);

export const ahmadi_img = importAll(
  require.context("../Images/Projects/Ahmadi Club", false, /\.(png|jpe?g|svg)$/)
);

export const al_arbi_img = importAll(
  require.context("../Images/Projects/Al Arabi Club", false, /\.(png|jpe?g|svg)$/)
);

// console.log(al_arbi_img);

export const chips = importAll(
  require.context("../Images/Projects/Chips 360", false, /\.(png|jpe?g|svg)$/)
);

export const ishbiliya_img = importAll(
  require.context("../Images/Projects/Ishbiliya School", false, /\.(png|jpe?g|svg)$/)
);

export const kuwait_club_img = importAll(
  require.context("../Images/Projects/Kuwait Club", false, /\.(png|jpe?g|svg)$/)
);

export const obada_img = importAll(
  require.context("../Images/Projects/Obada Bin Samed School", false, /\.(png|jpe?g|svg)$/)
);

export const private_villa_img = importAll(
  require.context("../Images/Projects/Private Villa", false, /\.(png|jpe?g|svg)$/)
);

export const salmiya_img = importAll(
  require.context("../Images/Projects/Salmiya Theater", false, /\.(png|jpe?g|svg)$/)
);

export const sindbad_img = importAll(
  require.context("../Images/Projects/Sindbad", false, /\.(png|jpe?g|svg)$/)
);

export const slaughter_img = importAll(
  require.context("../Images/Projects/Slaughter House", false, /\.(png|jpe?g|svg)$/)
);

export const toby_img = importAll(
  require.context("../Images/Projects/Toby_s Estate", false, /\.(png|jpe?g|svg)$/)
);

export const tribe_img = importAll(
  require.context("../Images/Projects/Tribe", false, /\.(png|jpe?g|svg)$/)
);
// console.log(abu_all);

export const abu = {
  img: abu_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const ahmadi = {
  img: ahmadi_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const al_arbi = {
  img: al_arbi_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};


export const chips360 = {
  img: chips,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const ishbiliya = {
  img: ishbiliya_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const kuwait_club = {
  img: kuwait_club_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const obada = {
  img: obada_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const private_villa = {
  img: private_villa_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const salmiya = {
  img: salmiya_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const sindbad = {
  img: sindbad_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const slaughter = {
  img: slaughter_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const toby = {
  img: toby_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};

export const tribe = {
  img: tribe_img,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed neque eu metus hendrerit feugiat at ut ipsum. Curabitur blandit felis eleifend tortor fermentum imperdiet. Praesent lacus ipsum, blandit in lorem suscipit, ultricies luctus nulla. Quisque quis enim mi. Nulla tincidunt eu magna non vulputate. Morbi risus est, pulvinar eget diam vel, maximus egestas nunc. In varius, magna viverra euismod commodo, justo ipsum vulputate tellus, nec tristique velit tortor id lectus. Donec dapibus turpis vitae massa hendrerit vehicula. Nullam sed auctor risus, sit amet faucibus tellus. Nullam blandit turpis sit amet purus tempor, efficitur ullamcorper eros tempus. Vivamus et blandit nisl, eu tincidunt ex. Donec molestie sollicitudin varius. Ut vel leo fermentum, lobortis enim a, commodo nulla. Mauris eget ligula eget urna volutpat ullamcorper maximus in diam. Vivamus vel tellus massa. Morbi gravida consectetur accumsan. Morbi ornare ipsum in diam fermentum tempor. Vestibulum in ultrices libero, non volutpat quam. Aliquam erat volutpat. Praesent faucibus odio vitae erat tristique suscipit. Proin eu vestibulum sem. In dolor nisl, porttitor id blandit suscipit, feugiat a velit. In porttitor, mauris ac mollis blandit, nunc lacus facilisis dui, eget vehicula nisl lorem ac tortor. Mauris semper, arcu nec fringilla blandit, nunc nunc consequat sem, sed placerat mi orci vel purus. Cras id porttitor ante, eget aliquet neque. Vestibulum non enim sem. Sed at massa tempus, auctor tellus sit amet, laoreet magna. Etiam cursus eros ipsum, id luctus augue aliquam nec.",
};