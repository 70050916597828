export const importAll = (r) => {
  let images = {};
  let img_arr = [];
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  Object.values(images).map((i) => {
    img_arr.push(i.default);
  });
  // console.log(images);
  return img_arr;
};

export const all_exe_images = importAll(
  require.context(
    "../Images/People/Executive Management",
    false,
    /\.(png|jpe?g|svg|JPE?G)$/
  )
);

export const all_team_images = importAll(
  require.context("../Images/People/Team KIC", false, /\.(png|jpe?g|svg)$/)
);

export const all_people_images = importAll(
  require.context("../Images/People/all_people", false, /\.(png|jpe?g|svg)$/)
);

export const exe_data = [
  { name: "Badar Al Sabah", position: "CEO & Engineer", desc: [""] },
  { name: "Abdul Maula", position: "Architect Engineer", desc: [""] },
  { name: "Eng. Atif", position: "Engineer", desc: [""] },
  { name: "Emad Hosni", position: "Engineer", desc: [""] },
  { name: "Hani Salib", position: "Engineer", desc: [""] },
  { name: "Karam Megalla", position: "Engineer", desc: [""] },
  { name: "Marcos Kamel", position: "Engineer", desc: [""] },
  { name: "Mohammed Gaffer", position: "Engineer", desc: [""] },
  { name: "Magdy Shawky", position: "", desc: [""] },
];

export const team_data = [
  { name: "Abdul Wahab", position: "", desc: [""] },
  { name: "Ahmed Gomma", position: "", desc: [""] },
  { name: "Ayoob Shakar", position: "", desc: [""] },
  { name: "Devarajan", position: "", desc: [""] },
  { name: "Farha Abu Farge", position: "", desc: [""] },
  { name: "Haisam Mohammed", position: "", desc: [""] },
  { name: "Hassan Abd Raheem", position: "", desc: [""] },
  { name: "Hassan Abdulla", position: "", desc: [""] },
  { name: "Jinu Joseph", position: "", desc: [""] },
  { name: "Joseph Kunju", position: "", desc: [""] },
  { name: "Karunakaran", position: "", desc: [""] },
  { name: "Khalid Saad", position: "", desc: [""] },
  { name: "Marcos Samuel", position: "", desc: [""] },
  { name: "Mina Edward", position: "", desc: [""] },
  { name: "Mohammad  Khasim", position: "", desc: [""] },
  { name: "Muruj Nasim", position: "", desc: [""] },
  { name: "Rajeevan Pakkan", position: "", desc: [""] },
  { name: "Rasheed Butt", position: "", desc: [""] },
  { name: "Riyadh Dawood", position: "", desc: [""] },
  { name: "Sarath Sasi", position: "", desc: [""] },
  { name: "Shaban Zaki", position: "", desc: [""] },
  { name: "Sudeesh", position: "", desc: [""] },
  { name: "Victor", position: "", desc: [""] },
  { name: "Meet the Team", position: "", desc: [""] },
  { name: "Meet the Team", position: "", desc: [""] },
];
export const ordered_data = [
  { name: "Eng. Bader Al Sabah", position: "CEO & Director", desc: [""] },
  { name: "Eng. Karam Megalla", position: "General Manager", desc: [""] },
  { name: "Eng Mahmoud", position: "Project Incharge", desc: [""] },
  { name: "Eng. Atif", position: "Project Manager", desc: [""] },
  { name: "Eng. Enas", position: "Electrical Engineer", desc: [""] },
  { name: "Mr. Magdy Shawky", position: "Finance Manager", desc: [""] },
  { name: "Mr. Ahmed Gomma", position: "Accountant", desc: [""] },
  { name: "Ms. Farah", position: "Human Resource", desc: [""] },
  { name: "Mina Edward", position: "IT Dept", desc: [""] },
  { name: "Mohammad Khasim", position: "Cashier", desc: [""] },
  { name: "Hassan Abdulla", position: "Representative", desc: [""] },
  { name: "Khalid Saad", position: "PR", desc: [""] },
  { name: "Murouj Nasim", position: "PR", desc: [""] },
  { name: "Ms. Arya", position: "Secretary", desc: [""] },
  { name: "Eng. Hani Salib", position: "Site Engineer", desc: [""] },
  { name: "Eng. Emad Hosni", position: "Architectural Engineer", desc: [""] },
  { name: "Eng. Abdul Maula", position: "Architect Engineer", desc: [""] },
  { name: "Eng. Marcos Kamel", position: "Architectural Engineer", desc: [""] },
  { name: "Eng.Mohammed Gaffer", position: "Site Engineer", desc: [""] },
  { name: "Eng. Marcos Samuel", position: "Site Engineer", desc: [""] },
  { name: "Eng. Ahmed Amin", position: "Architectural Engineer", desc: [""] },
  { name: "Joseph Kunju", position: "Procurement", desc: [""] },
  { name: "Devarajan", position: "Foreman", desc: [""] },
  { name: "Haisam Mohammed", position: "Admin", desc: [""] },
  { name: "Jinu Joseph", position: "Factory Worker", desc: [""] },
  { name: "Karunakaran", position: "Factory Worker", desc: [""] },
  { name: "Rajeevan Pakkan", position: "Factory Worker", desc: [""] },
  { name: "Rasheed Butt", position: "Factory Worker", desc: [""] },
  { name: "Riyadh Dawood", position: "Factory Worker", desc: [""] },
  { name: "Sarath Sasi", position: "Factory Worker", desc: [""] },
  { name: "Shaban Zaki", position: "Factory Worker", desc: [""] },
  { name: "Sudeesh", position: "Factory Worker", desc: [""] },
  { name: "Victor", position: "Foreman", desc: [""] },
  { name: "Abdul Wahab", position: "Foreman", desc: [""] },
  { name: "Meet the team", position: "", desc: [""] },
  { name: "Meet the team", position: "", desc: [""] },
];
