import abu from '../Images/Projects/Abu Fatira Villa/photo4.jpg'
import ahmadi from '../Images/Projects/Ahmadi Club/CIMG2388.JPG'
import arbi from '../Images/Projects/Al Arabi Club/DSC02625.jpg'
import chips from '../Images/Projects/Chips 360/CHIPS_FINAL-11.jpg'
import isbiliya from '../Images/Projects/Ishbiliya School/TWS_0017.jpg'
import kuwait from '../Images/Projects/Kuwait Club/Kuwait Club.jpg'
import obada from '../Images/Projects/Obada Bin Samed School/60.jpg'
import private_villa from '../Images/Projects/Private Villa/20141212_130959.jpg'
import salmiya from '../Images/Projects/Salmiya Theater/DSC02587.jpg'
import sindbad from '../Images/Projects/Sindbad/Sindbad.jpg'
import slaughter from '../Images/Projects/Slaughter House/11.jpg'
import toby from '../Images/Projects/Toby_s Estate/photo2.jpg'
import tribe from '../Images/Projects/Tribe/photo1.jpg'

export const projects = [
  {
    name: "Abu Fatira Villa",
    image: abu,
    year:'2020'
  },
  {
    name: "Ahmadi Club",
    image: ahmadi,
    year:'2020'
  },
  {
    name: "Al Arabi Club",
    image: arbi,
    year:'2020'
  },
  {
    name: "Chips 360",
    image: chips,
    year:'2019'
  },
  {
    name: "Ishbiliya School",
    image: isbiliya,
    year:'2020'
  },
  {
    name: "Kuwait Club",
    image: kuwait,
    year:'2020'
  },
  {
    name: "Obada Bin Samed School",
    image: obada,
    year:'2020'
  },
  {
    name: "Private Villa",
    image: private_villa,
    year:'2020'
  },
  {
    name: "Salmiya Theater",
    image: salmiya,
    year:'2020'
  },
  {
    name: "Sindbad",
    image: sindbad,
    year:'2020'
  },
  {
    name: "Slaughter House",
    image: slaughter,
    year:'2020'
  },
  {
    name: "Toby's Real Estate",
    image: toby,
    year:'2018'
  },
  {
    name: "Tribe",
    image: tribe,
    year:'2020'
  }
]