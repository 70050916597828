import { projects } from "./projects";
export const nav_projects = {
  section_name: "Projects",
  // sub_links: [
  //   {
  //     href: "",
  //     text: "Abu Fatira Villa",
  //   },
  //   {
  //     href: "",
  //     text: "Chips 360",
  //   },
  //   {
  //     href: "",
  //     text: "Private Villa",
  //   },
  //   {
  //     href: "",
  //     text: "Tob's Estate",
  //   },
  //   {
  //     href: "",
  //     text: "Tribe",
  //   },
  // ],
  sub_links: [...projects],
};

export const nav_profile = {
  section_name: "Profile",
  sub_links: [
    // {
    //   href: "",
    //   text: "Process",
    // },
    {
      href: "",
      text: "People",
    },
    {
      href: "",
      text: "Partners",
    },
  ],
};

export const nav_people = {
  section_name: "People",
  sub_links: [
    {
      href: "",
      text: "Executive Management",
    },
    {
      href: "",
      text: "Team KIC",
    },
  ],
};

export const contact = [
  "North Sabhan - Block 10",
  "St. 3 - Building 131-133",
  "Tel : 24719820 - 24731186 -24764084 - 24762174",
  "Fax : 24719830",
  "Telex : 23839 PANELCO KT.",
  "P.O.Box : 24039 Safat",
  "13101 State of Kuwait",
  "kic@kiccenter.com",
];
