import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import Slider from "react-slick";

// data
import { homeImages, bottomText, onlyImages } from "../data/home";

import "../css/home.css";
// import "react-image-gallery/styles/css/image-gallery.css";

import NavBar from "../components/NavBar";

class Home extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
    img:onlyImages
  }
  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      afterChange: () => {
        // console.log("after change");
        this.setState((state) => ({ updateCount: state.updateCount + 1 }));
      },
      beforeChange: (current, next) => {
        // console.log("next", next, current);
        this.setState({ slideIndex: next });
      },
    };
    const { active, show_details, description, img, slideIndex } = this.state;
    return (
      <div className="page">
        <NavBar />
        <div className="left">
          <div className="home-bottom">
            <p className="home-bottom-text">{bottomText}</p>
            <h4 className="home-bottom-sub-text">
              Custom Design + Precision Built.
            </h4>
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            <div className="carousel-btns">
              <div className="c-prev" onClick={() => {
                var next;
                if (slideIndex === 0) {
                  next = img.length - 1;
                } else {
                  next = slideIndex - 1;
                }
                this.slider.slickGoTo(next);
              }}></div>
              <div className="c-next" onClick={() => {
                var next;
                if (slideIndex === img.length - 1) {
                  next = 0;
                } else {
                  next = slideIndex + 1;
                }
                this.slider.slickGoTo(next);
              }}></div>
            </div>
            {/* <ImageGallery
              items={homeImages}
              showNav={true}
              showFullscreenButton={false}
              showPlayButton={false}
              className="carousel"
            /> */}
            <Slider {...settings} ref={(slider) => (this.slider = slider)}>
              {onlyImages.map((i) => (
                <div key={i} className="home-carousel-div">
                  <img src={i} alt={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
