import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

class MyApp extends React.Component {
  state = {
    black: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ black: false });
    }, 800);
  }

  render() {
    const { black } = this.state;
    return (
      <div className={black ? "main black" : "main"}>
        <App />
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <MyApp />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
